.map {
    position: relative;
    width: 100vw;
    display: flex;
    justify-content: center;
}

.logo-wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.phone-wrapper p {
    color: #fff;
    font-size: 20px;
    text-align: center;
}

@media (max-width: 900px) {
    .phone-wrapper {
        position: absolute;
        z-index: 1;
        left: 5vw;
        bottom: 12vh;
        width: 90vw;
        background: linear-gradient(60deg, #3629a2, #00ceff);
        padding: 24px;
        box-sizing: border-box;
        border-radius: 16px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    .gradient-multiline {
        position: relative;
        margin: 0 auto;
        text-align: center;
        font-size: 2rem;
        line-height: 1.5em;
        overflow: hidden;
    }

    .icon {
        width: 30px;
        padding: 8px;
        background-color: #fff;
        border-radius: 50%;
        margin: 8px;
    }

    .map img {
        position: absolute;
        width: 90vw;
        left: 5vw;
    }

    .logo {
        margin-top: 25px;
        width: 90vw;
    }
}

@media (min-width: 900px) {
    .phone-wrapper {
        position: absolute;
        z-index: 1;
        left: 100px;
        top: 35%;
        background: linear-gradient(60deg, #3629a2, #00ceff);
        padding: 24px;
        border-radius: 16px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    .gradient-multiline {
        position: relative;
        margin: 0 auto;
        text-align: center;
        font-size: 4rem;
        line-height: 1.5em;
        overflow: hidden;
    }

    .icon {
        width: 40px;
        padding: 12px;
        background-color: #fff;
        border-radius: 50%;
        margin: 12px;
    }

    .map img {
        position: absolute;
        max-width: 50vw;
        max-height: 70vh;
    }

    .logo {
        margin-top: 25px;
        height: 15vh;
    }
}

.icons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fade-in {
    animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.scale-in-center {
    animation: scale-in-center .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.heartbeat {
    animation: heartbeat 1.5s ease-in-out infinite both;
}


@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes heartbeat {
    from {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out;
    }
    10% {
        transform: scale(0.91);
        animation-timing-function: ease-in;
    }
    17% {
        transform: scale(0.98);
        animation-timing-function: ease-out;
    }
    33% {
        transform: scale(0.87);
        animation-timing-function: ease-in;
    }
    45% {
        transform: scale(1);
        animation-timing-function: ease-out;
    }
}

.gradient-multiline span a, .gradient-multiline span  {
    color: #fff;
    text-decoration: none;
    padding: 0.225rem 0.5rem;
}